import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Home/Navbar.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../Assets/Logo/Logo.png";
import ComingSoon from "../../Components/Universal/CommingSoon";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const location = useLocation();

  const openNav = () => {
    setNav(!nav);
  };

  const handleChatBtnClick = () => {
    ComingSoon();
  };

  const isServicesPage = () => {
    return location.pathname.includes('/services');
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <img src={Logo} className="logo"/>
        <Link to="/">
          SugamShree <span className="navbar-sign"></span>
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className={`navbar-links ${location.pathname === '/' ? 'active' : ''}`}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            className={`navbar-links ${isServicesPage() ? 'active' : ''}`}
          >
            Services
          </Link>
        </li>
        <li>
        <Link to="/myteam" className={`navbar-links ${location.pathname === '/myteam' ? 'active' : ''}`}>
            My Team
          </Link>
        </li>
        <li>
        <Link to="/about-us" className={`navbar-links ${location.pathname === '/about-us' ? 'active' : ''}`}>
            About Us
          </Link>
        </li>
        <li>
        <Link to="/contact" className={`navbar-links ${location.pathname === '/contact' ? 'active' : ''}`}>
            Contact Us
          </Link>
        </li>
      </ul>

    <Link to="/sign-in">
      <button
        className="navbar-btn"
        type="button"
        disabled={isButtonDisabled}
        onClick={handleChatBtnClick}
      >
        <FontAwesomeIcon icon={faCommentDots} /> Sign In
      </button>
      </Link>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />  
        </div>

        <ul className="mobile-navbar-links">
        <li>
            <Link onClick={openNav} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/services">
              Services
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/myteam">
              MyTeam
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/about-us">
              About Us
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;

import React from "react";
import handshake from "../../Assets/Services/handshake.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faAddressBook} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../../Styles/Home/BookAppointment.css";

function BookAppointment() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/contact");
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img src={handshake} alt="Team Photo" className="ba-image1" />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Why Choose SugamShree</span>
        </h3>
        <p className="ba-description">
          Explore the reasons to choose SugamShree Workforce Management Private Limited for your workforce and business management needs. Experience professionalism, reliability, and cost-effective solutions, making SugamShree your strategic business partner. Join us on a journey towards efficient workforce management and business success.
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Experienced Professionals in Various Fields
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Comprehensive Solutions for Data Handling, Analysis, Staffing, and more
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Cutting-Edge Technology and Infrastructure
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Reliable and Cost-Effective Services
        </p>

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleBookAppointmentClick}
        >
          <FontAwesomeIcon icon={faAddressBook} /> Contact Us
        </button>
      </div>
    </div>
  );
}

export default BookAppointment;

import react from "react";
import { Helmet } from "react-helmet";
import ServicesPageCard from "../../Components/Services/ServicesPageCard";
import HeaderBox from "../../Components/Universal/HeaderBox";
import Footer from "../../Components/Home/Footer";
import Software from "../../Assets/Services/Software.jpg"
import AppDevelopment from "../../Assets/Services/AppDevelopment.jpg"
import WebDevelopment from "../../Assets/Services/WebDevelopment.jpg"
import APPS from "../../Assets/Services/APPS.jpg";
import "../../Styles/Services/ProfessionalServices.css";

function ItSolutions() {
  return (
    <>
      <HeaderBox title="IT Solutions" />
      <Helmet>
        <title>IT Solutions | SugamShree</title>
      </Helmet>
      <div className="professional-services-container">
        <ServicesPageCard
          title="1. Website Development"
          name="Website Development"
          src={WebDevelopment}
          description="SUGAMSHREE offers cutting-edge Website Development services, tailored to elevate your online presence. Our expert team focuses on responsive design, ensuring your website is accessible and visually appealing across all devices. From user-friendly interfaces to seamless navigation, we prioritize the user experience. Whether you need a corporate website, e-commerce platform, or a custom web solution, SUGAMSHREE is your partner in bringing your digital vision to life."
        />

        <ServicesPageCard
          title="2. App Development"
          name="App Development"
          src={APPS}
          description="Unlock the potential of mobile applications with SUGAMSHREE's App Development services. Our team specializes in creating intuitive and feature-rich apps for both iOS and Android platforms. From conceptualization to deployment, we focus on functionality, security, and optimal performance. Whether you are looking to enhance customer engagement or streamline internal processes, trust SUGAMSHREE to deliver mobile solutions that align with your business objectives."
        />

        <ServicesPageCard
          title="3. Software Development"
          name="Software Development"
          src={Software}
          description="SUGAMSHREE specializes in bespoke Software Development, addressing the unique requirements of your business. Our development team creates tailored solutions, including Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), and business intelligence tools. With a focus on aligning technology with your operational needs, SUGAMSHREE ensures that your software enhances efficiency, productivity, and overall business performance."
        />
      </div>
      <Footer />
    </>
  );
}

export default ItSolutions;

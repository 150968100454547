import React from "react";
import "../../Styles/Universal/HeaderBox.css"

function HeaderBox (props) {
    return(
        <div className="services-title-box">
        <h1>{props.title}</h1>
      </div>
    
)};

export default HeaderBox;

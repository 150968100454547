import React from "react";
import { Link } from "react-router-dom";
import "../../Styles/Services/ServiceCard.css";

const ServiceCard = ({ title, description, imageSrc, linkTo }) => (
    <Link to={linkTo} className="service-card-link">
      <div className="service-card">
        <h3>{title}</h3>
        <img src={imageSrc} alt={title} />
        <p>{description}</p>
      </div>
    </Link>
  );

  export default ServiceCard;
  
// ComingSoonPage.js
import React from "react";
import { Helmet } from "react-helmet";
import comming from "../../Assets/ComingSoon/coming.jpg";
import "../../Styles/Universal/ComingSoon.css";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <Helmet>
        <title>Sign In | SugamShree</title>
      </Helmet>

      <img src={comming} />
    </div>
  );
};

export default ComingSoon;

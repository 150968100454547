import React from "react";
import "../../Styles/Home/Footer.css";
import SubscribeNewsletter from "../Universal/SubscribeNewsletter";
import '@fortawesome/fontawesome-free/css/all.min.css';
// import '~@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              SugamShree{" "}
              <span className="ft-sign">
                | Your Trusted Business Solutions Partner
              </span>
            </p>
            <p className="ft-description">
            SugamShree Workforce Management Private Limited stands as your unparalleled strategic business ally, seamlessly delivering avant-garde and highly economical solutions across diverse domains. Our commitment to excellence ensures that we not only meet but exceed your expectations, fostering a dynamic and prosperous partnership for sustained success.
            </p>
          </div>

          <SubscribeNewsletter />
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Our Services</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/services/professional-services"}>GST & Taxation</Link>
            </li>
            <li>
              <Link to={"/services"}>Background Verification</Link>
            </li>
            <li>
              <Link to={"/services"}>Data Handling & Analysis</Link>
            </li>
            <li>
              <Link to={"/services/it-solutions"}>IT Solutions</Link>
            </li>
            <li>
              <Link to={"/services/work-contracts"}>Construction Works</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal Information</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>General Info</Link>
            </li>
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/legal"}>Terms of Services</Link>
            </li>
            <li>
              <Link to={"/legal"}>Workforce Solutions</Link>
            </li>
            <li>
              <Link to={"/legal"}>How We Operate</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Contact Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:info@sugamshree.com">info@sugamshree.com</a>
            </li>
            <li>
              <a href="tel:+91 8004090809">+91 80 0409 0809</a>
            </li>
            <li>
              <span>Head Office: G1/458, Lonapur,<br /> Gomtinagar, Lucknow-226010</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 SugamShree. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://linkedin.com/company/sugamshree"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>

          <li>
            <a
              href="[12:55 p.m., 2024-03-02] @/\/\!T: Page hi banaiye
              [1:33 p.m., 2024-03-02] Uttam Bhaiya: https://m.facebook.com/profile.php/?id=61557166941800&name=xhp_nt__fb__action__open_user"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
          </li>

          <li>
            <a
              href="https://twitter.com/sugamshree"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import InformationCard from "../Universal/InformationCard";
import { faBuilding, faUserTie, faFileContract } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Home/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          SugamShree Workforce Management Private Limited is your strategic business partner, delivering cutting-edge and cost-effective solutions across various domains. Our comprehensive range of services is designed to optimize your workforce management, ensuring efficiency and compliance in every aspect of your business operations.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Staffing & Payroll Services"
          description="Our Staffing & Payroll Services are tailored to meet the dynamic demands of your workforce. From recruitment and onboarding to payroll management, we provide end-to-end solutions to ensure that your workforce is efficiently managed and compliant with all regulations."
          icon={faUserTie}
        />

        <InformationCard
          title="Documentation & Due Diligence"
          description="Navigate through the complexities of documentation and due diligence with SugamShree. Our experts, including Chartered Accountants, Company Secretaries, and Legal professionals, handle the intricate processes, ensuring that your business transactions and compliance are always in order."
          icon={faFileContract}
        />

        <InformationCard
          title="Infrastructure Management"
          description="SugamShree takes pride in providing world-class infrastructure management services. From online communication facilities to leveraging the latest technical know-how, we ensure optimal performance in managing your business infrastructure, both onsite and offshore."
          icon={faBuilding}
        />
      </div>
    </div>
  );
}

export default Info;

import react from "react";
import { Helmet } from "react-helmet";
import ServicesPageCard from "../../Components/Services/ServicesPageCard";
import HeaderBox from "../../Components/Universal/HeaderBox";
import Bankings from "../../Assets/Services/Bankings.jpg";
import GST from "../../Assets/Services/GST.jpg";
import Footer from "../../Components/Home/Footer";
import { Link } from "react-router-dom";
import "../../Styles/Services/ProfessionalServices.css";

function ProfessionalServices() {
  return (
    <>
      <HeaderBox title="Professional Services" />
      <Helmet>
        <title>Professional Services | SugamShree</title>
      </Helmet>
      <div className="professional-services-container">
        <ServicesPageCard
          title="1. Banking, Insurance & Finance"
          name="Banking, Insurance & Finance"
          src={Bankings}
          description="SUGAMSHREE's Banking, Insurance & Finance professionals offer a comprehensive suite of services to meet the diverse financial needs of our clients. Our financial analysts conduct in-depth market research, risk assessments, and investment analyses to provide tailored strategies for wealth management and financial growth. We specialize in loan structuring, ensuring optimal terms for our clients, and provide expert advisory services in the complex realm of insurance. Our goal is to empower clients to make informed decisions in managing their financial portfolios, guiding them towards financial security and prosperity."
        />

        <ServicesPageCard
          title="2. GST & Taxation"
          name="GST & Taxation"
          src={GST}
          description="At SUGAMSHREE, we understand the intricate landscape of Goods and Services Tax (GST) and taxation. Our dedicated team of experts offers a comprehensive range of services, from initial GST registration and filing to ongoing compliance and advisory. We go beyond simple tax planning, providing strategic insights to optimize tax positions, navigate regulatory changes, and ensure businesses stay ahead in a dynamic financial environment. Trust SUGAMSHREE to be your partner in maintaining tax efficiency and compliance with the latest regulations."
        />

        <ServicesPageCard
          title="3. Accounting"
          name="Accounting"
          //   src={Accounting}
          description="SUGAMSHREE's Accounting services extend beyond traditional bookkeeping. Our team of seasoned professionals offers a suite of financial management solutions, including budgeting, financial statement analysis, and internal auditing. With a commitment to accuracy and transparency, we provide businesses with reliable financial information for strategic decision-making. Whether you are a small business or a large enterprise, SUGAMSHREE ensures that your financial records are in order, allowing you to focus on core business operations and growth."
        />

        <ServicesPageCard
          title="4. Regulators & Securities"
          name="Regulators & Securities"
          //   src={Regulators}
          description="Navigating the realm of regulators and securities requires expertise and precision. At SUGAMSHREE, we offer a comprehensive suite of services covering compliance assessments, legal advisory on securities trading, and representation before regulatory bodies. Our team is well-versed in the ever-evolving regulatory landscape, ensuring that businesses adhere to industry standards and legal requirements. Trust us to be your guide in managing regulatory complexities, safeguarding your business, and fostering a culture of compliance."
        />

        <ServicesPageCard
          title="5. Corporate Commercial & Governance"
          name="Corporate Commercial & Governance"
          //   src={CorporateCommercial}
          description="SUGAMSHREE's Corporate Commercial & Governance services are designed to provide businesses with robust legal support. Our team specializes in contract drafting, mergers and acquisitions support, and corporate governance consulting. We go beyond legal compliance, working to enhance the ethical practices and overall governance of your business. Whether you are a startup or an established corporation, SUGAMSHREE is your trusted partner in legal matters, ensuring your business operates ethically and in full compliance with legal frameworks."
        />

        <ServicesPageCard
          title="6. Start-Ups & Business Ideas"
          name="Start-Ups & Business Ideas"
          //   src={Startups}
          description="Embarking on a new business venture? SUGAMSHREE is your go-to partner for startups and business ideas. Our end-to-end support includes ideation workshops, business plan development, market research, and funding strategies. We leverage our expertise to guide entrepreneurs in turning their business ideas into successful ventures. Whether you need assistance in securing funding, market positioning, or navigating legal considerations, SUGAMSHREE is committed to helping startups thrive in a competitive business landscape."
        />

        <ServicesPageCard
          title="7. Labour Law"
          name="Labour Law"
          //   src={LabourLaw}
          description="SUGAMSHREE stands as a reliable partner in navigating the complex landscape of labor laws. Our Labour Law services cover every aspect of workforce management, from drafting employment contracts to dispute resolution and compliance audits. We work to ensure that businesses maintain fair employment practices within the legal framework, fostering a harmonious work environment and mitigating risks associated with labor law non-compliance."
        />
      </div>
      <Footer />
    </>
  );
}

export default ProfessionalServices;

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../../Styles/AddressBoxes/AddressBox.css";

const AddressBox = ({ mainaddress, address, latitude, longitude }) => {
  const position = [latitude, longitude];

  return (
    <div className="address-box">
      <div className="address-details">
        <h3>{mainaddress}</h3>
        <p>{address}</p>
      </div>
      <div className="map-container">
        <MapContainer center={position} zoom={15} style={{ height: "300px", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>{address}</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default AddressBox;

import React from "react";
import { Helmet } from "react-helmet";
import ContactBox from "../../Components/ContactUs/ContactBox";
import HeaderBox from "../../Components/Universal/HeaderBox";
import AddressBox from "../../Components/ContactUs/AddressBoxes/AddressBox";
import AddressBox2 from "../../Components/ContactUs/AddressBoxes/AddressBox2";
import Footer from "../../Components/Home/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faFontAwesome,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faShareSquare, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/ContactUs/Contact.css";
// Make sure these versions are compatible
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faLinkedin, faTwitter } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us | SugamShree</title>
      </Helmet>
      <HeaderBox title="Contact Us" />
      <div className="contact-box-container">
        <ContactBox
          icon={<FontAwesomeIcon icon={faPhoneAlt} />}
          title="+91 80 0409 0809"
        />
        <ContactBox
          icon={<FontAwesomeIcon icon={faAt} />}
          title="info@sugamshree.com"
        />
        <ContactBox
          style={{ marginTop: "-5px" }}
          icon={
            <>
              <FontAwesomeIcon icon={faShareNodes} /> <br />
              <a
                href="https://www.linkedin.com/your-linkedin-account"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="https://twitter.com/your-twitter-account"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  style={{ marginLeft: "40px" }}
                />
              </a>
            </>
          }
        />
      </div>

      <div className="contact-address">
        <AddressBox
          mainaddress="Registered Office"
          address="Plot No: G1, Lonapur, Sector 7, Gomti Nagar, Lucknow, Uttar Pradesh 226010"
          latitude={26.82625}
          longitude={81.04944}
        />
        {/* <AddressBox2
          mainaddress="Head Office"
          address="Your Office Address"
          latitude={25.5069}
          longitude={83.212596}
        /> */}
      </div>
      <Footer />
    </>
  );
}

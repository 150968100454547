import React from "react";
import SolutionStep from "../../Components/Universal/SolutionStep";
import { Helmet } from "react-helmet";
import HeaderBox from "../../Components/Universal/HeaderBox";
import Team from "../../Assets/Services/Team.jpg";
import Footer from "../../Components/Home/Footer";
import "../../Styles/AboutUs/About.css";

function About() {
  return (
    <>
        <HeaderBox title="About Us"/>
      <div className="about-section" id="about">
      <Helmet>
        <title>About Us | SugamShree</title>
      </Helmet>
        <div className="about-image-content">
          <img src={Team} alt="Team Photo" className="about-image1" />
        </div>

        <div className="about-text-content">
          <h3 className="about-title">
            <span>About SugamShree</span>
          </h3>
          <p className="about-description">
            Welcome to SugamShree Workforce Management Private Limited, your
            strategic business partner for cutting-edge and cost-effective
            solutions. Incorporated on 08-Jan-2024, SugamShree is led by a group
            of seasoned professionals with over a decade of experience in the
            corporate field.
          </p>

          <h4 className="about-text-title">Our Mission</h4>
          <p className="about-description">
            At SugamShree, our mission is to empower businesses with innovative
            workforce solutions, enabling them to thrive in an ever-evolving
            global market.
          </p>

          <h4 className="about-text-title">Why Choose Us?</h4>
          <p className="about-description">
            With a relentless commitment to excellence, SugamShree stands out as
            a reliable partner. Our client-centric approach, coupled with a
            dynamic and diverse team, ensures that we deliver top-notch services
            tailored to your unique business needs.
          </p>

          <h4 className="about-text-title">Our Core Values</h4>
          <p className="about-description">
            Integrity, Innovation, Collaboration, and Excellence – these values
            drive every aspect of our business, from client interactions to
            internal processes. We believe in building enduring relationships
            based on trust and mutual success.
          </p>

          <SolutionStep
            title="Comprehensive Solutions"
            description="SugamShree takes pride in offering an extensive array of comprehensive solutions, catering to the intricate needs of businesses in the modern landscape. Whether it's conducting thorough Customer End Point Verification, meticulous Due Diligence, or efficient Onboarding processes, our services ensure reliability, compliance, and cost-effectiveness, providing a solid foundation for your business success."
          />

          <SolutionStep
            title="Diverse Professional Team"
            description="Our strength lies in our diverse team, a harmonious blend of skilled professionals, including Chartered Accountants, Company Secretaries, Advocates, Engineers, Architects, and more. United by our commitment to data quality management, we bring a wealth of expertise to the table, offering comprehensive solutions that traverse the entire data quality lifecycle."
          />

          <SolutionStep
            title="World-Class Infrastructure"
            description="At SugamShree, our commitment to excellence is mirrored in our world-class infrastructure. Our robust technological backbone ensures round-the-clock online communication between onsite and offshore teams. Leveraging the latest industry know-how, we provide a guarantee of optimal performance, underscoring our dedication to delivering bespoke solutions tailored to your unique business requirements."
          />

          <SolutionStep
            title="Innovative Technology Integration"
            description="SugamShree embraces cutting-edge technologies, integrating innovation into our solutions. From AI-driven analytics to automation, we stay at the forefront of technological advancements to enhance the efficiency and effectiveness of our services."
          />

          <SolutionStep
            title="Client-Centric Approach"
            description="At SugamShree, our clients are at the heart of everything we do. We prioritize understanding your unique needs, fostering transparent communication, and delivering tailor-made solutions. Your success is our success, and we strive to exceed your expectations."
          />

          <SolutionStep
            title="Global Compliance Assurance"
            description="Operating in a complex regulatory landscape requires diligence and expertise. SugamShree ensures global compliance in every aspect of our services. Our dedicated compliance team stays abreast of international regulations, providing you with peace of mind and a seamless experience."
          />

          <SolutionStep
            title="Data Security and Confidentiality"
            description="Securing your sensitive data is non-negotiable for us. SugamShree implements robust data security measures, following industry best practices. Our commitment to confidentiality and privacy ensures that your information is handled with the utmost care and in compliance with global standards."
          />

          <SolutionStep
            title="Strategic HR Consultancy"
            description="SugamShree excels in providing strategic HR consultancy services. Our team of HR experts collaborates with your organization to develop and implement human resource strategies that align with your business objectives, fostering a positive and productive workplace."
          />

          <SolutionStep
            title="Agile Project Management"
            description="Adopting an agile project management approach, SugamShree ensures flexibility and adaptability in delivering solutions. We break down complex projects into manageable sprints, promoting collaboration, transparency, and the timely achievement of milestones."
          />

          <SolutionStep
            title="Employee Development Programs"
            description="Investing in the growth and development of your workforce is paramount. SugamShree offers tailored employee development programs, including training, skill enhancement, and leadership workshops. Empower your team to reach their full potential and contribute to organizational success."
          />

          <SolutionStep
            title="Sustainable Business Practices"
            description="Beyond immediate solutions, SugamShree is committed to promoting sustainable business practices. We integrate environmentally conscious approaches into our operations, ensuring a positive impact on the planet while contributing to the long-term success of your business."
          />

          <SolutionStep
            title="Continuous Improvement Initiatives"
            description="We believe in the power of continuous improvement. SugamShree actively seeks feedback, conducts regular assessments, and implements iterative enhancements. This dedication to refinement ensures that our solutions evolve alongside your business, always staying ahead of the curve."
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;

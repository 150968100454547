// Import necessary dependencies
import React from "react";
import { Helmet } from "react-helmet";
import WorkContracts from "../../Assets/Services/WorkContracts.jpg";
import ProfessionalImages from "../../Assets/Services/ProfessionalImages.jpg";
import ItSolutions from "../../Assets/Services/ItSolutions.jpg";
import ITS from "../../Assets/Services/ITS.jpg";
import ManPower from "../../Assets/Services/ManPower.jpg";
import HeaderBox from "../../Components/Universal/HeaderBox";
import ServiceCard from "../../Components/Services/ServiceCard";
import ProfessionalServices from "./ProfessionalServices";
import Footer from "../../Components/Home/Footer";
import "../../Styles/Services/Services.css";

// Service page component
const Services = () => (
  <div className="services-container">
    <Helmet>
      <title>Our Services | SugamShree</title>
    </Helmet>
    <HeaderBox title="Our Services" />
    
    <section className="card-containner">
      {/* Professional Services */}
      <ServiceCard
        title="Professional Services"
        linkTo="/services/professional-services"
        imageSrc={ProfessionalImages}
        style={{ maxWidth: "300px" }}
      />

      {/* Work Contracts */}
      <ServiceCard
        title="Work Contracts"
        linkTo="/services/work-contracts"
        imageSrc={WorkContracts}
        style={{ maxWidth: "300px" }}
      />
    </section>

    <section className="card-containner-1">
      {/* Man Power Managements */}
      <ServiceCard
        title="Man Power Managements"
        linkTo="/services/man-power-managements"
        imageSrc={ManPower}
        style={{ maxWidth: "300px" }}
      />

      {/* IT Solutions */}
      <ServiceCard
        title="IT Solutions"
        linkTo="/services/it-solutions"
        imageSrc={ITS}
        style={{ maxWidth: "300px" }}
      />
    </section>
    <Footer />
  </div>
  
);

export default Services;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./Components/Universal/Layout";
import Home from "./Pages/Home/Home";
import Legal from "./Pages/Other/Legal";
import NotFound from "./Pages/Other/NotFound";
import Appointment from "./Pages/Other/Appointment";
import Services from "./Pages/Services/Services";
import MyTeam from "./Pages/MyTeam/MyTeam";
import About from "./Pages/AboutUs/About";
import Contact from "./Pages/ContactUs/Contact";
import ProfessionalServices from "./Pages/Services/ProfessionalServices";
import ManPowerManagements from "./Pages/Services/ManPowerManagements";
import WorkContracts from "./Pages/Services/WorkContracts";
import ItSolutions from "./Pages/Services/ITSolutions";
import ComingSoon from "./Components/Universal/CommingSoon";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <br />
                <br />
                <Home />
              </Layout>
            }
          />
          <Route
            path="/services"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <Services />
              </Layout>
            }
          />
          <Route
            path="/myteam"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <MyTeam />
              </Layout>
            }
          />
          <Route
            path="/about-us"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <About />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <Contact />
              </Layout>
            }
          />
          <Route
            path="/services/professional-services"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <ProfessionalServices />
              </Layout>
            }
          />
          <Route
            path="/services/man-power-managements"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <ManPowerManagements />
              </Layout>
            }
          />
          <Route
            path="/services/work-contracts"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <WorkContracts />
              </Layout>
            }
          />
          <Route
            path="/services/it-solutions"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <br />
                <ItSolutions />
              </Layout>
            }
          />
          <Route
            path="/legal"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <Legal />
              </Layout>
            }
          />
          <Route
            path="/sign-in"
            element={
              <Layout>
                <br />
                <br />
                <br />
                <br />
                <ComingSoon />
              </Layout>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

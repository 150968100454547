import react from 'react';
import "../../Styles/Services/ServicesPageCard.css"

    function ServicesPageCard(props) {
        return (
          <>
            <div className="services-cards">
              <h2>{props.title}</h2>
              <img src={props.src} />
              <p>{props.description}</p>
            </div>
          </>
        );
      }

export default ServicesPageCard;

import react from "react";
import { Helmet } from "react-helmet";
import ServicesPageCard from "../../Components/Services/ServicesPageCard";
import HeaderBox from "../../Components/Universal/HeaderBox";
import Footer from "../../Components/Home/Footer";
import "../../Styles/Services/ProfessionalServices.css";

function ManPowerManagements() {
  return (
    <>
      <HeaderBox title="Man Power Managements" />
      <Helmet>
        <title>Man Power Managements | SugamShree</title>
      </Helmet>
      <div className="professional-services-container">
        <ServicesPageCard
          title="1. Skilled Manpower Supply"
          name="Skilled Manpower Supply"
        //   src={SkilledManpower}
          description="SUGAMSHREE is your partner in securing skilled manpower across various industries. Whether you require IT professionals, finance experts, or engineering talent, our Manpower Management services ensure that your business has access to the right skills to drive success. From recruitment to onboarding, trust SUGAMSHREE to be your reliable source for top-tier professionals."
        />

        <ServicesPageCard
          title="2. Unskilled Labour Supply"
          name="Unskilled Labour Supply"
        //   src={UnskilledLabour}
          description="For industries requiring unskilled labor, SUGAMSHREE offers a dependable supply of workers. From construction sites to manufacturing units, our Manpower Management ensures that your business has the workforce it needs to meet operational demands. We prioritize safety, reliability, and efficiency, contributing to the smooth functioning of your business operations."
        />
      </div>
      <Footer />
    </>
  );
}

export default ManPowerManagements;

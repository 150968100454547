import react from "react";
import { Helmet } from "react-helmet";
import ServicesPageCard from "../../Components/Services/ServicesPageCard";
import HeaderBox from "../../Components/Universal/HeaderBox";
import Footer from "../../Components/Home/Footer";
import "../../Styles/Services/ProfessionalServices.css";

function WorkContracts() {
  return (
    <>
      <HeaderBox title="Work Contracts" />
      <Helmet>
        <title>Work Contracts | SugamShree</title>
      </Helmet>
      <div className="professional-services-container">
        <ServicesPageCard
          title="1. Construction Works"
          name="Construction Works"
          //   src={ConstructionWorks}
          description="SUGAMSHREE is your trusted partner for Construction Works, committed to delivering excellence in quality, efficiency, and timely project completion. Our dedicated team of experienced professionals oversees every aspect of the construction process with meticulous attention to detail. From the initial project planning and design phases to the execution and procurement of materials, we ensure that your vision is translated into a successful and well-executed construction project.Specializing in a wide range of construction endeavors, including residential buildings, commercial spaces, and large-scale infrastructure projects, SUGAMSHREE brings a wealth of expertise to the table. Our collaborative approach involves close communication with clients, architects, and subcontractors, fostering a seamless construction process. We prioritize safety, sustainability, and innovative solutions, ensuring that your construction project not only meets but exceeds expectations.With a proven track record of delivering successful construction projects across diverse sectors, SUGAMSHREE stands as a reliable choice for those seeking a partner committed to delivering top-notch construction services. Trust us to turn your vision into reality, creating spaces that inspire and endure."
        />

        <ServicesPageCard
          title="2. Government Contracts"
          name="Government Contracts"
        //   src={GovernmentContracts}
          description="Navigating the intricate landscape of Government Contracts requires a partner with expertise, precision, and a deep understanding of regulatory frameworks. SUGAMSHREE is your go-to firm for Government Contracts, offering a comprehensive suite of services to assist businesses in successfully bidding for and executing government projects.Our seasoned team of professionals provides strategic guidance throughout the entire government contracting process. From initial proposal writing and bid submission to contract execution and compliance, SUGAMSHREE ensures that businesses not only meet but exceed the stringent standards set by government agencies. We specialize in a wide range of industries, delivering projects that align with government regulations and quality expectations.With a commitment to transparency, integrity, and excellence, SUGAMSHREE has successfully partnered with numerous businesses in securing and executing government contracts. Our in-depth understanding of procurement processes and compliance requirements positions us as a trusted advisor, helping your business navigate the complexities of government projects and achieve success in the public sector."
        />
      </div>
      <Footer />
    </>
  );
}

export default WorkContracts;

import React from "react";
import { Helmet } from "react-helmet";
import Rafi from "../../Assets/OurTeam/Rafi.jpg";
import Naveen from "../../Assets/OurTeam/Naveen.jpg";
import Samar from "../../Assets/OurTeam/Samar.jpg";
import Uttam from "../../Assets/OurTeam/Uttam.jpg";
import Ajay from "../../Assets/OurTeam/Ajay.jpg";
import Deepika from "../../Assets/OurTeam/Deepika.jpg";
import CAVirendra from "../../Assets/OurTeam/CAVirendra.jpg";
import CASobhit from "../../Assets/OurTeam/CASobhit.jpg";
import SobhitCA from "../../Assets/OurTeam/SobhitCA.jpg";
import Pankaj from "../../Assets/OurTeam/Pankaj.jpg";
import AmitUpadhyay from "../../Assets/OurTeam/AmitUpadhyay.jpg";
import Footer from "../../Components/Home/Footer";
import MyTeamCard from "../../Components/Myteam/MyTeamCard";
import HeaderBox from "../../Components/Universal/HeaderBox";
import MyTeamDetailsCard from "../../Components/Myteam/MyTeamDetailsCard";
import "../../Styles/MyTeam/MyTeam.css"; // Make sure to adjust the path according to your file structure

const TeamPage = () => (
  <>
    <HeaderBox title="Meet Our Directors" />
    <div className="team-container">
      <Helmet>
        <title>Our Team | SugamShree</title>
      </Helmet>

      <MyTeamDetailsCard
        src={Naveen}
        ImageDetails="Naveen Kumar Pandey"
        name="NAVEEN KUMAR PANDEY"
        details="NAVEEN KUMAR PANDEY, Director of Company is Commerce Graduate from
      University of Allahabad.. He .has more than 10 years of rich
      experience in Banking sector with him. He has worked on various
      positions ranging from Operations, Credit , Internal Audit,
      Concurrent Audit, Statutory Audit, Revenue Audit, Due Diligence,
      Prepares asset, liability, and capital account entries by compiling
      and analyzing account information, Provides financial information to
      management by researching and analyzing accounting data; preparing
      reports etc."
      />

      <MyTeamDetailsCard
        src={Rafi}
        ImageDetails="Ehtesam Rafi"
        name="EHTESAM RAFI"
        details="EHTESHAM RAFI, Director of Company is Law graduate from Awadh
      University. He has more than 15 years of experience in the field of
      Due Diligence, Internal Audit , Taxation, Accounting, ROC Matters &
      Compliances, Prepares payments by verifying documentation, and
      requesting disbursements, Summarizes current financial status by
      collecting information; preparing balance sheet, profit and loss
      statement, and other reports, Maintains customer confidence and
      protects operations by keeping financial information confidential,
      etc."
      />

      <MyTeamDetailsCard
        src={Samar}
        ImageDetails="Samar Bahadur Singh"
        name="SAMAR BAHADUR SINGH"
        details=" SAMAR BAHADUR SINGH, Director of Company is Post Graduate in
      Commerce from Awadh University. He has more than 15 years of
      experience in the field of Taxation, Corporate Finance, Reporting
      Skills, SFAS Rules, Maintains accounting controls by preparing and
      recommending policies and procedures, Reconciles financial
      discrepancies by collecting and analyzing account information etc."
      />

      <MyTeamDetailsCard
        src={Uttam}
        ImageDetails="Uttam Baranwal"
        name="UTTAM BARANWAL"
        details="  UTTAM BARANWAL, Director of Company is Law graduate from Awadh
      University. He has more than 10 years of experience in the field of
      Due Diligence, Internal Audit , Taxation, Summarizes current
      financial status by collecting information; preparing balance sheet,
      profit and loss statement, and other reports, Maintains financial
      security by following internal controls, Complies with federal,
      state, and local financial legal requirements by studying existing
      and new legislation, enforcing adherence to requirements, and
      advising management on needed actions etc."
      />
    </div>

    <HeaderBox title="Meet Our Consultants" />
    <div className="Team-card">
      <MyTeamCard img={Pankaj} name="PANKAJ SHUKLA" title="(Advocate)" />

      <MyTeamCard
        img={SobhitCA}
        name="SHOBHIT SINHA"
        title="(Chartered Accountant)"
      />

      <MyTeamCard
        img={CAVirendra}
        name="VIRENDRA SINGH"
        title="(Chartered Accountant)"
      />

      <MyTeamCard
        img={Deepika}
        name="Deepika Sinha"
        title="(Company Secretary)"
      />

      <MyTeamCard img={Ajay} name="AJAY K YADAV" title="(Advocate)" />

      <MyTeamCard
        img={AmitUpadhyay}
        name="Amit A. Upadhyay"
        title="(Advocate)"
      />
    </div>

    <Footer />
  </>
);

export default TeamPage;

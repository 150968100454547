export const customerReviews = [
  {
    "name": "Priya Sharma",
    "location": "Lucknow, Uttar Pradesh, India",
    "message": "SugamShree Workforce Management has been instrumental in streamlining our operations. Their innovative solutions have significantly boosted our efficiency and overall workforce management."
  },
  {
    "name": "Raj Singh",
    "location": "Varanasi, Uttar Pradesh, India",
    "message": "Our partnership with SugamShree has proven to be a game-changer for our business. Their workforce optimization strategies have brought about tangible improvements, ensuring sustained growth."
  },
  {
    "name": "Neha Patel",
    "location": "Prayagraj, Uttar Pradesh, India",
    "message": "SugamShree's expertise in staffing solutions is unparalleled. The seamless integration of their services has immensely contributed to our team's productivity and success."
  },
  {
    "name": "Amit Verma",
    "location": "Kanpur, Uttar Pradesh, India",
    "message": "SugamShree's workforce management solutions have exceeded our expectations. Their commitment to excellence and strategic approach have made them a valued partner in our business journey."
  },
  {
    "name": "Ananya Gupta",
    "location": "Ghazipur, Uttar Pradesh, India",
    "message": "We've experienced a transformative shift in our workforce dynamics with SugamShree. Their payroll management solutions are not just efficient but also cost-effective."
  },
  {
    "name": "Vikram Malhotra",
    "location": "Chandauli, Uttar Pradesh, India",
    "message": "SugamShree's dedication to delivering cutting-edge solutions has had a profound impact on our business operations. Their workforce management expertise is commendable."
  },
  {
    "name": "Pooja Joshi",
    "location": "Kunda, Uttar Pradesh, India",
    "message": "SugamShree's workforce optimization strategies have been instrumental in elevating our business to new heights. Their commitment to excellence is evident in every aspect of our partnership."
  },
  {
    "name": "Aditya Kapoor",
    "location": "Gazipur, Uttar Pradesh, India",
    "message": "SugamShree's innovative staffing solutions have proven to be a catalyst for our growth. Their comprehensive approach ensures a seamless and efficient workforce."
  },
  {
    "name": "Meera Khanna",
    "location": "Jhansi, Uttar Pradesh, India",
    "message": "SugamShree's payroll management has simplified our processes, allowing us to focus on core business activities. Their expertise and professionalism are truly commendable."
  },
  {
    "name": "Rahul Desai",
    "location": "Banda, Uttar Pradesh, India",
    "message": "SugamShree has been a trusted partner in our workforce journey. Their workforce management solutions are tailored to our needs, ensuring optimal efficiency."
  }
];

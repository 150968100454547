import react from "react";
import "../../Styles/MyTeam/MyTeamDetailsCard.css";

function MyTeamDetailsCard(props) {
  return (
    <div className="director-card">
      <img src={props.src} alt={props.ImageDetails} />
      <div className="director-info">
        <h3>{props.name}</h3>
        <p>{props.details}</p>
      </div>
    </div>
  );
}

export default MyTeamDetailsCard;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../Styles/Universal/LegalDocs.css";

function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
        <Link to="/">
          Sugam<span className="legal-siteSign">Shree</span>
        </Link>
      </h1>

      <div className="legal-text-content">
        <p className="legal-title">About SugamShree</p>
        <p className="legal-description">
          Welcome to SugamShree, your premier online platform for comprehensive workforce solutions. Our commitment is to empower your business by delivering top-tier services in staffing, payroll, and workforce management. By accessing and utilizing our platform, you acknowledge and agree to abide by the terms specified in our Privacy Policy and Terms of Service.
        </p>

        <p className="legal-title">Privacy Policy</p>
        <p className="legal-description">
          We prioritize the privacy of your workforce. Our Privacy Policy elucidates the methods employed in collecting, utilizing, and safeguarding personal and professional data. We guarantee secure data handling, ensuring the utmost confidentiality of your information.
        </p>

        <p className="legal-title">Terms of Service</p>
        <p className="legal-description">
          Using SugamShree entails adherence to our Terms of Service. This encompasses guidelines for platform usage, engagement with our services, and the responsibilities of both parties involved. Familiarizing yourself with these terms is imperative to ensure a seamless experience for all users.
        </p>

        <p className="legal-title">Workforce Solutions</p>
        <p className="legal-description">
          SugamShree connects you with tailored workforce solutions designed to catalyze business growth. Our offerings span staffing, payroll management, and workforce optimization. We emphasize the importance of transparent communication and collaboration to achieve mutual success.
        </p>

        <p className="legal-title">How We Operate</p>
        <p className="legal-description">
          SugamShree streamlines workforce management. You can explore our specialized services, engage in consultations, and tailor solutions to your business needs. Our team provides personalized advice and strategic plans. For urgent matters, always prioritize immediate attention from your local workforce management authority.
        </p>
      </div>

      <div className="legal-footer">
        <p>© 2024 SugamShree. All rights reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;

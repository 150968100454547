import React from "react";
import "../../Styles/ContactUs/ContactBox.css";

const ContactBox = ({ title, icon }) => (
  <div className="contact-box">
    <span className="box-icon">{icon}</span>
    <h3>{title}</h3>
    {/* Additional content can be added here */}
  </div>
);

export default ContactBox;

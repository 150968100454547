import React from "react";
import Navbar from "../../Components/Home/Navbar";
import { Helmet } from "react-helmet";
import Hero from "../../Components/Home/Hero";
import Info from "../../Components/Home/Info";
// import About from "../Components/About";
import BookAppointment from "../../Components/Home/BookAppointment";
import Reviews from "../../Components/Home/Reviews";
import Footer from "../../Components/Home/Footer";

function Home() {
  return (
    <div className="home-section">
      <Helmet>
        <title>SugamShree | Your Trusted Business Solutions Partner</title>
      </Helmet>
      <Navbar />
      <Hero />
      <Info />
      {/* <About /> */}
      <BookAppointment />
      <Reviews />
      <Footer />
    </div>
  );
}

export default Home;
